import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/shared/guards/auth.guard';
import { RoleGuard } from './auth/shared/guards/role.guard';
import { AuthContainerComponent } from './layouts/auth/components/auth-container/auth-container.component';
import { CommonContainerComponent } from './layouts/common/components/common-container/common-container.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'auth',
        component: AuthContainerComponent,
        loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
      },
      {
        path: 'new-project',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./new-project/new-project.module').then(module => module.NewProjectModule)
      },
      {
        path: 'projects',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./projects/projects.module').then(module => module.ProjectsModule)
      },
      // {
      //   path: 'daily-tasks',
      //   canActivate: [AuthGuard],
      //   component: CommonContainerComponent,
      //   loadChildren: () =>
      //     import('./daily-tasks/daily-tasks.module').then(module => module.DailyTasksModule)
      // },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((module) => module.DashboardModule)
      },
      {
        path: 'schedule',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./schedule/schedule.module').then(module => module.ScheduleModule)
      },
      {
        path: 'scheduler',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./scheduler/scheduler.module').then(module => module.SchedulerModule)
      },
      {
        path: 'washing-scheduler',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./washing-scheduler/washing-scheduler.module').then(module => module.WashingSchedulerModule)
      },
      {
        path: 'drives',
        canActivate: [AuthGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./drives/drives.module').then(module => module.DrivesModule)
      },
      {
        path: 'analytics',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./analytics/analytics.module').then(module => module.AnalyticsModule)
      },
      {
        path: 'billing',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./billing/billing.module').then(module => module.BillingModule)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./settings/settings.module').then(module => module.SettingsModule)
      },
      {
        path: 'notifications',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./notification/notification.module').then(module => module.NotificationModule)
      },
      {
        path: 'clients',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./clients/clients.module').then(module => module.ClientsModule)
      },
      {
        path: 'employees',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./employees/employees.module').then(module => module.EmployeesModule)
      },
      {
        path: 'my-info',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./clients/components/my-info/my-info.module').then(module => module.MyInfoModule)
      },
      {
        path: 'a-settings',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./admin-settings/admin-settings.module').then(module => module.AdminSettingsModule)
      },
      {
        path: 'leads',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./pending-users/pending-users.module').then(module => module.PendingUsersModule)
      },
      {
        path: 'trucks',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./trucks/trucks.module').then(module => module.TrucksModule)
      },
      {
        path: 'checkout',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./checkout/checkout.module').then(module => module.CheckoutModule)
      },
      {
        path: 'garbage-companies',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./garbage-companies/garbage-companies.module').then(module => module.GarbageCompaniesModule)
      },
      {
        path: 'organizer',
        canActivate: [AuthGuard, RoleGuard],
        component: CommonContainerComponent,
        loadChildren: () =>
          import('./organizer/organizer.module').then(module => module.OrganizerModule)
      },
      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
      { path: '**', redirectTo: '/auth/login' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
